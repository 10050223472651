<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Divulgação</h1>
    <form
      name="taskForm"
      class="p-col-12 p-md-12"
      @submit.prevent="submitServiceForm"
    >
      <div class="p-fluid p-formgrid p-grid p-col-12 p-md-6">
        <div class="p-field p-col-12 p-md-12 p-mt-2">
          <span class="p-float-label">
            <InputText
              type="text"
              name="responsible"
              v-model="disclosure.responsible"
              v-bind:class="[
                { 'p-error': errors.has('responsible') },
                'form-control',
              ]"
              v-validate="'required'"
              :disabled="!editable"
            />
            <label for="inputtext">Responsável</label>
          </span>
          <small v-if="errors.has('responsible')" class="p-error" role="alert">
            Responsável é obrigatório
          </small>
        </div>

        <div class="p-field p-col-12 p-md-12 p-mt-2">
          <span class="p-float-label">
            <InputText
              type="text"
              name="title"
              v-model="disclosure.title"
              v-bind:class="[
                { 'p-error': errors.has('title') },
                'form-control',
              ]"
              v-validate="'required'"
              :disabled="!editable"
            />
            <label for="inputtext">Título</label>
          </span>
          <small v-if="errors.has('title')" class="p-error" role="alert">
            Título é obrigatório
          </small>
        </div>

        <div class="p-field p-col-12 p-md-12 p-mt-2">
          <span class="p-float-label">
            <Textarea
              type="text"
              name="description"
              v-model="disclosure.description"
              rows="10"
              v-bind:class="[
                { 'p-error': errors.has('description') },
                'form-control',
              ]"
              v-validate="'required'"
              :disabled="!editable"
            />
            <label for="inputtext">Descrição</label>
          </span>
          <small v-if="errors.has('description')" class="p-error" role="alert">
            Descrição é obrigatório
          </small>
        </div>

        <div class="p-field p-col-12 p-md-6 p-mt-2">
          <span class="p-float-label">
            <Dropdown
              name="internal_disclosure"
              v-model="disclosure.internal_disclosure"
              :options="selectButtonOpt"
              optionLabel="name"
              optionValue="value"
              v-bind:class="[
                { 'p-error': errors.has('internal_disclosure') },
                'form-control',
              ]"
              :disabled="!editable"
              v-validate="'required'"
            />
            <label for="inputtext">Divulgação Interna</label>
          </span>
          <small
            v-if="errors.has('internal_disclosure')"
            class="p-error"
            role="alert"
          >
            Divulgação Interna é obrigatório
          </small>
        </div>

        <div class="p-field p-col-12 p-md-6 p-mt-2">
          <span class="p-float-label">
            <Dropdown
              name="external_disclosure"
              v-model="disclosure.external_disclosure"
              :options="selectButtonOpt"
              optionLabel="name"
              optionValue="value"
              v-bind:class="[
                { 'p-error': errors.has('external_disclosure') },
                'form-control',
              ]"
              :disabled="!editable"
              v-validate="'required'"
            />
            <label for="inputtext">Divulgação Externa</label>
          </span>
          <small
            v-if="errors.has('external_disclosure')"
            class="p-error"
            role="alert"
          >
            Divulgação Externa é obrigatório
          </small>
        </div>
        <div
          class="p-field p-col-12 p-md-12"
          style="color: #a3acba"
          v-if="
            disclosure.internal_disclosure == 1 &&
            disclosure.internal_disclosure_at != null &&
            disclosure.internal_disclosure_at != '0000-00-00 00:00:00'
          "
        >
          Publicado internamente em: {{ disclosure.internal_disclosure_at }}
        </div>
        <div
          class="p-field p-col-12 p-md-12"
          style="color: #a3acba"
          v-if="
            disclosure.external_disclosure == 1 &&
            disclosure.external_disclosure_at != null &&
            disclosure.external_disclosure_at != '0000-00-00 00:00:00'
          "
        >
          Publicado externamente em: {{ disclosure.external_disclosure_at }}
        </div>
      </div>

      <Button
        v-if="editable"
        label="Gravar"
        icon="pi pi-check"
        class="p-button-success"
        @click="save"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger p-ml-1"
        @click="cancel"
      />
    </form>
  </div>
</template>

<script>
import disclosureservice from "../services/disclosure.service";

export default {
  name: "DisclosureView",
  data() {
    return {
      loading: true,

      selectButtonOpt: [
        { name: "Sim", value: 1 },
        { name: "Não", value: 0 },
      ],
      disclosure: {},
      editable: true,
    };
  },
  created() {
    this.loading = true;
    this.getDisclosure();
  },
  methods: {
    getDisclosure() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        disclosureservice
          .getDisclosureById(this.$route.params.id)
          .then((response) => {
            console.log(response);
            this.loading = false;
            return (this.disclosure = response);
          })
          .then(() => this.isEditable());
      }
    },
    isEditable() {
      if (
        this.disclosure.internal_disclosure == 1 &&
        this.disclosure.internal_disclosure_at != null &&
        this.disclosure.internal_disclosure_at != "0000-00-00 00:00:00"
      ) {
        return (this.editable = false);
      }
      if (
        this.disclosure.external_disclosure == 1 &&
        this.disclosure.external_disclosure_at != null &&
        this.disclosure.external_disclosure_at != "0000-00-00 00:00:00"
      ) {
        return (this.editable = false);
      }
    },
    cancel() {
      this.$router.push(`/disclosure`);
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }

        this.$validator.pause();
        this.$validator.reset();
        let formData = new FormData();
        for (var key in this.disclosure) {
          formData.append(key, this.disclosure[key]);
        }
        disclosureservice.saveDisclosure(formData).then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao gravar",
              life: 3000,
            });
          }
        });
      });
    },
  },
};
</script>
